export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = `CLOSE_SNACKBAR`;
export const REMOVE_SNACKBAR = `REMOVE_SNACKBAR`;

export const LOGIN_ACTION = 'LOGIN_ACTION';

export const LOG_OUT_ACTION = 'LOG_OUT_ACTION';

export const LOGIN_ACTION_SUCCESS = `${LOGIN_ACTION}_SUCCESS`;
export const LOGIN_ACTION_FAILED = `${LOGIN_ACTION}_FAILED`;

export const REGISTER_ACTION = 'REGISTER_ACTION';
export const REGISTER_ACTION_SUCCESS = `${REGISTER_ACTION}_SUCCESS`;
export const REGISTER_ACTION_FAILED = `${REGISTER_ACTION}_FAILED`;

export const VERIFY_EMAIL_ACTION = 'VERIFY_EMAIL_ACTION';
export const VERIFY_EMAIL_ACTION_SUCCESS = `${VERIFY_EMAIL_ACTION}_SUCCESS`;
export const VERIFY_EMAIL_ACTION_FAILED = `${VERIFY_EMAIL_ACTION}_FAILED`;

export const FORGET_PASSWORD_ACTION = 'FORGET_PASSWORD_ACTION';
export const FORGET_PASSWORD_ACTION_SUCCESS = `${FORGET_PASSWORD_ACTION}_SUCCESS`;
export const FORGET_PASSWORD_ACTION_FAILED = `${FORGET_PASSWORD_ACTION}_FAILED`;

export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD_ACTION';
export const RESET_PASSWORD_ACTION_SUCCESS = `${RESET_PASSWORD_ACTION}_SUCCESS`;
export const RESET_PASSWORD_ACTION_FAILED = `${RESET_PASSWORD_ACTION}_FAILED`;

export const GET_PRICING_ACTION = 'GET_PRICING_ACTION';
export const GET_PRICING_ACTION_SUCCESS = `${GET_PRICING_ACTION}_SUCCESS`;
export const GET_PRICING_ACTION_FAILED = `${GET_PRICING_ACTION}_FAILED`;

export const GET_PROFILE_ACTION = 'GET_PROFILE_ACTION';
export const GET_PROFILE_ACTION_SUCCESS = `${GET_PROFILE_ACTION}_SUCCESS`;
export const GET_PROFILE_ACTION_FAILED = `${GET_PROFILE_ACTION}_FAILED`;

export const GET_QUESTION_TYPES_CATEGORIZED_ACTION =
  'GET_QUESTION_TYPES_CATEGORIZED_ACTION';
export const GET_QUESTION_TYPES_CATEGORIZED_ACTION_SUCCESS = `${GET_QUESTION_TYPES_CATEGORIZED_ACTION}_SUCCESS`;
export const GET_QUESTION_TYPES_CATEGORIZED_ACTION_FAILED = `${GET_QUESTION_TYPES_CATEGORIZED_ACTION}_FAILED`;

export const GET_QUESTIONS_FOR_CATEGORY_ACTION =
  'GET_QUESTIONS_FOR_CATEGORY_ACTION';
export const GET_QUESTIONS_FOR_CATEGORY_ACTION_SUCCESS = `${GET_QUESTIONS_FOR_CATEGORY_ACTION}_SUCCESS`;
export const GET_QUESTIONS_FOR_CATEGORY_ACTION_FAILED = `${GET_QUESTIONS_FOR_CATEGORY_ACTION}_FAILED`;

export const GET_TYPE_QUESTIONS_ACTION = 'GET_TYPE_QUESTIONS_ACTION';
export const GET_TYPE_QUESTIONS_ACTION_SUCCESS = `${GET_TYPE_QUESTIONS_ACTION}_SUCCESS`;
export const GET_TYPE_QUESTIONS_ACTION_FAILED = `${GET_TYPE_QUESTIONS_ACTION}_FAILED`;

export const GET_QUESTION_ACTION = 'GET_QUESTION_ACTION';
export const GET_QUESTION_ACTION_SUCCESS = `${GET_QUESTION_ACTION}_SUCCESS`;
export const GET_QUESTION_ACTION_FAILED = `${GET_QUESTION_ACTION}_FAILED`;

export const GET_COMMENTS_ACTIONS = 'GET_COMMENTS_ACTIONS';
export const GET_COMMENTS_ACTIONS_SUCCESS = `${GET_COMMENTS_ACTIONS}_SUCCESS`;
export const GET_COMMENTS_ACTIONS_FAILED = `${GET_COMMENTS_ACTIONS}_FAILED`;

export const ANSWER_QUESTION_ACTION = 'ANSWER_QUESTION_ACTION';
export const ANSWER_QUESTION_ACTION_SUCCESS = `${ANSWER_QUESTION_ACTION}_SUCCESS`;
export const ANSWER_QUESTION_ACTION_FAILED = `${ANSWER_QUESTION_ACTION}_FAILED`;

export const ANSWER_MOCK_ACTION = 'ANSWER_MOCK_ACTION';
export const ANSWER_MOCK_ACTION_SUCCESS = `${ANSWER_MOCK_ACTION}_SUCCESS`;
export const ANSWER_MOCK_ACTION_FAILED = `${ANSWER_MOCK_ACTION}_FAILED`;

export const NEW_COMMENT_ACTION = 'NEW_COMMENT_ACTION';
export const NEW_COMMENT_ACTION_SUCCESS = `${NEW_COMMENT_ACTION}_SUCCESS`;
export const NEW_COMMENT_ACTION_FAILED = `${NEW_COMMENT_ACTION}_FAILED`;

export const SET_SELECTED_QUESTION = 'SET_SELECTED_QUESTION';
export const RESET_SELECTED_QUESTION = 'RESET_SELECTED_QUESTION';
export const SET_IS_ANSWERING = 'SET_IS_ANSWERING';
export const SET_ANSWERS = 'SET_ANSWERS';
export const CLEAR_ANSWERS = 'CLEAR_ANSWERS';

export const SET_ACCEPTABLE_ANSWERS = 'SET_ACCEPTABLE_ANSWERS';

export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

export const SET_MY_ANSWER = 'SET_MY_ANSWER';

export const GET_MY_HISTORY_ACTION = 'GET_MY_HISTORY_ACTION';
export const GET_MY_HISTORY_ACTION_SUCCESS = 'GET_MY_HISTORY_ACTION_SUCCESS';
export const GET_MY_HISTORY_ACTION_FAILED = 'GET_MY_HISTORY_ACTION_FAILED';

export const BOOKMARK_ACTION = 'BOOKMARK_ACTION';
export const BOOKMARK_ACTION_SUCCESS = 'BOOKMARK_ACTION_SUCCESS';
export const BOOKMARK_ACTION_FAILED = 'BOOKMARK_ACTION_FAILED';

export const SHARE_REPONSE_ACTION = 'SHARE_REPONSE_ACTION';
export const SHARE_REPONSE_ACTION_SUCCESS = 'SHARE_REPONSE_ACTION_SUCCESS';
export const SHARE_REPONSE_ACTION_FAILED = 'SHARE_REPONSE_ACTION_FAILED';

export const GET_SHARED_RESPONSE_ACTION = 'GET_SHARED_RESPONSE_ACTION';
export const GET_SHARED_RESPONSE_ACTION_SUCCESS =
  'GET_SHARED_RESPONSE_ACTION_SUCCESS';
export const GET_SHARED_RESPONSE_ACTION_FAILED =
  'GET_SHARED_RESPONSE_ACTION_FAILED';

export const GET_B_M_ACTION = 'GET_B_M_ACTION';
export const GET_B_M_ACTION_SUCCESS = 'GET_B_M_ACTION_SUCCESS';
export const GET_B_M_ACTION_FAILED = 'GET_B_M_ACTION_FAILED';

export const GET_PRACTICED_ACTION = 'GET_PRACTICED_ACTION';
export const GET_PRACTICED_ACTION_SUCCESS = 'GET_PRACTICED_ACTION_SUCCESS';
export const GET_PRACTICED_ACTION_FAILED = 'GET_PRACTICED_ACTION_FAILED';

export const GET_NOT_PRACTICED_ACTION = 'GET_NOT_PRACTICED_ACTION';
export const GET_NOT_PRACTICED_ACTION_SUCCESS =
  'GET_NOT_PRACTICED_ACTION_SUCCESS';
export const GET_NOT_PRACTICED_ACTION_FAILED = 'GET_NOT_PRACTICED_ACTION_FAILED';

export const GET_EXAM_Q_ACTION = 'GET_EXAM_Q_ACTION';
export const GET_EXAM_Q_ACTION_SUCCESS = 'GET_EXAM_Q_ACTION_SUCCESS';
export const GET_EXAM_Q_ACTION_FAILED = 'GET_EXAM_Q_ACTION_FAILED';

export const GET_DIFFICULT_ACTION = 'GET_DIFFICULT_ACTION';
export const GET_DIFFICULT_ACTION_SUCCESS = 'GET_DIFFICULT_ACTION_SUCCESS';
export const GET_DIFFICULT_ACTION_FAILED = 'GET_DIFFICULT_ACTION_FAILED';

export const SET_QUESTION_LIST = 'SET_QUESTION_LIST';
export const CLEAR_QUESTION_LIST = 'CLEAR_QUESTION_LIST';

export const PUT_DIFFICULT_QUESTION = 'PUT_DIFFICULT_QUESTION';
export const PUT_DIFFICULT_QUESTION_SUCCESS = 'PUT_DIFFICULT_QUESTION_SUCCESS';
export const PUT_DIFFICULT_QUESTION_FAILED = 'PUT_DIFFICULT_QUESTION_FAILED';

export const REPORT_QUESTION_ACTION = 'REPORT_QUESTION_ACTION';
export const REPORT_QUESTION_ACTION_SUCCESS = 'REPORT_QUESTION_ACTION_SUCCESS';
export const REPORT_QUESTION_ACTION_FAILED = 'REPORT_QUESTION_ACTION_FAILED';

export const GET_WEB_QUESTION_LIST = 'GET_WEB_QUESTION_LIST';
export const GET_WEB_QUESTION_LIST_SUCCESS = 'GET_WEB_QUESTION_LIST_SUCCESS';
export const GET_WEB_QUESTION_LIST_FAILED = 'GET_WEB_QUESTION_LIST_FAILED';

export const GET_ACCEPTABLE_ANSWER = 'GET_ACCEPTABLE_ANSWER';
export const GET_ACCEPTABLE_ANSWER_SUCCESS = 'GET_ACCEPTABLE_ANSWER_SUCCESS';
export const GET_ACCEPTABLE_ANSWER_FAILED = 'GET_ACCEPTABLE_ANSWER_FAILED';

export const SET_RATE = 'SET_RATE';
export const SET_RATE_SUCCESS = 'SET_RATE_SUCCESS';
export const SET_RATE_FAILED = 'SET_RATE_FAILED';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILED = 'GET_CITIES_FAILED';

export const INDEX_MOCK = 'INDEX_MOCK';
export const INDEX_MOCK_SUCCESS = 'INDEX_MOCK_SUCCESS';
export const INDEX_MOCK_FAILED = 'INDEX_MOCK_FAILED';

export const GET_MOCK = 'GET_MOCK';
export const GET_MOCK_SUCCESS = 'GET_MOCK_SUCCESS';
export const GET_MOCK_FAILED = 'GET_MOCK_FAILED';

export const START_MOCK = 'START_MOCK';
export const START_MOCK_SUCCESS = 'START_MOCK_SUCCESS';
export const START_MOCK_FAILED = 'START_MOCK_FAILED';

export const GET_QUESTIONS_MOCK = 'GET_QUESTIONS_MOCK';
export const GET_QUESTIONS_MOCK_SUCCESS = 'GET_QUESTIONS_MOCK_SUCCESS';
export const GET_QUESTIONS_MOCK_FAILED = 'GET_QUESTIONS_MOCK_FAILED';

export const GET_MOCK_USER_LIST = 'GET_MOCK_USER_LIST';
export const GET_MOCK_USER_LIST_SUCCESS = 'GET_MOCK_USER_LIST_SUCCESS';
export const GET_MOCK_USER_LIST_FAILED = 'GET_MOCK_USER_LIST_FAILED';

export const GET_MOCK_SKILL_QUESTIONS = 'GET_MOCK_SKILL_QUESTIONS';
export const GET_MOCK_SKILL_QUESTIONS_SUCCESS = 'GET_MOCK_SKILL_QUESTIONS_SUCCESS';

export const SHOW_MOCK_USER = 'SHOW_MOCK_USER';
export const SHOW_MOCK_USER_SUCCESS = 'SHOW_MOCK_USER_SUCCESS';
export const SHOW_MOCK_USER_FAILED = 'SHOW_MOCK_USER_FAILED';

export const MOCK_ANSWER_SUBMITED_SUCCESS = 'MOCK_ANSWER_SUBMITED_SUCCESS';

export const GET_MOCK_ANSWER = 'GET_MOCK_ANSWER';
export const GET_MOCK_ANSWER_SUCCESS = 'GET_MOCK_ANSWER_SUCCESS';
export const GET_MOCK_ANSWER_FAILED = 'GET_MOCK_ANSWER_FAILED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const FILTER_MOCK_ANSWERS = 'FILTER_MOCK_ANSWERS';
export const FILTER_MOCK_ANSWERS_ACTION = 'FILTER_MOCK_ANSWERS_ACTION';

export const GET_SUBSCRIPTION_TYPE = 'GET_SUBSCRIPTION_TYPE';
export const GET_SUBSCRIPTION_TYPE_SUCCESS = 'GET_SUBSCRIPTION_TYPE_SUCCESS';

export const SUBSCRIPTION = 'SUBSCRIPTION';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_FAILED = 'SUBSCRIPTION_FAILED';
export const CLEAR_SUBSCRIPTION_ERROR = 'CLEAR_SUBSCRIPTION_ERROR';

export const PAYMENT = 'PAYMENT';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';

export const PAYPAL = 'PAYPAL';
export const PAYPAL_SUCCESS = 'PAYPAL_SUCCESS';
export const PAYPAL_FAILED = 'PAYPAL_FAILED';

export const STRIPE = 'STRIPE';
export const STRIPE_SUCCESS = 'STRIPE_SUCCESS';
export const STRIPE_FAILED = 'STRIPE_FAILED';

export const PACKAGE = 'PACKAGE';
export const PACKAGE_SUCCESS = 'PACKAGE_SUCCESS';
export const PACKAGE_FAILED = 'PACKAGE_FAILED';

export const GET_STATISTIC_ACTION = 'GET_STATISTIC_ACTION';
export const GET_STATISTIC_ACTION_SUCCESS = 'GET_STATISTIC_ACTION_SUCCESS';
export const GET_STATISTIC_ACTION_FAILED = 'GET_STATISTIC_ACTION_FAILED';

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';

export const FETCH_ALL_NOTIFICATIONS = 'FETCH_ALL_NOTIFICATIONS';
export const FETCH_ALL_NOTIFICATIONS_SUCCESS = 'FETCH_ALL_NOTIFICATIONS_SUCCESS';
export const FETCH_ALL_NOTIFICATIONS_FAILED = 'FETCH_ALL_NOTIFICATIONS_FAILED';
export const FETCH_ALL_UNREAD_NOTIFICATIONS = 'FETCH_ALL_UNREAD_NOTIFICATIONS';
export const FETCH_ALL_UNREAD_NOTIFICATIONS_SUCCESS =
  'FETCH_ALL_UNREAD_NOTIFICATIONS_SUCCESS';
export const FETCH_ALL_UNREAD_NOTIFICATIONS_FAILED =
  'FETCH_ALL_UNREAD_NOTIFICATIONS_FAILED';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAILED = 'MARK_AS_READ_FAILED';
export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
export const MARK_ALL_AS_READ_SUCCESS = 'MARK_ALL_AS_READ_SUCCESS';
export const MARK_ALL_AS_READ_FAILED = 'MARK_ALL_AS_READ_FAILED';

export const FETCH_ALL_SESSIONS = 'FETCH_ALL_SESSIONS';
export const FETCH_ALL_SESSIONS_SUCCESS = 'FETCH_ALL_SESSIONS_SUCCESS';
export const FETCH_ALL_SESSIONS_FAILED = 'FETCH_ALL_SESSIONS_FAILED';

export const FETCH_SESSION = 'FETCH_SESSION';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAILED = 'FETCH_SESSION_FAILED';

export const FETCH_USER_SESSION = 'FETCH_USER_SESSION';
export const FETCH_USER_SESSION_SUCCESS = 'FETCH_USER_SESSION_SUCCESS';
export const FETCH_USER_SESSION_FAILED = 'FETCH_USER_SESSION_FAILED';

export const FETCH_USER_SESSIONS = 'FETCH_USER_SESSIONS';
export const FETCH_USER_SESSIONS_SUCCESS = 'FETCH_USER_SESSIONS_SUCCESS';
export const FETCH_USER_SESSIONS_FAILED = 'FETCH_USER_SESSIONS_FAILED';

export const BOOKING_SESSION = 'BOOKING_SESSION';
export const BOOKING_SESSION_SUCCESS = 'BOOKING_SESSION_SUCCESS';
export const BOOKING_SESSION_FAILED = 'BOOKING_SESSION_FAILED';

export const CANCELING_SESSION = 'CANCELING_SESSION';
export const CANCELING_SESSION_SUCCESS = 'CANCELING_SESSION_SUCCESS';
export const CANCELING_SESSION_FAILED = 'CANCELING_SESSION_FAILED';

export const RESET_SESSION_BOOKING = 'RESET_SESSION_BOOKING';



export const GET_PACKAGE_INDEX = 'GET_PACKAGE_INDEX';
export const GET_PACKAGE_INDEX_SUCCESS = 'GET_PACKAGE_INDEX_SUCCESS';
export const GET_PACKAGE_INDEX_FAILED = 'GET_PACKAGE_INDEX_FAILED';

export const GET_PACKAGE_DETAILS = 'GET_PACKAGE_DETAILS';
export const GET_PACKAGE_DETAILS_SUCCESS = 'GET_PACKAGE_DETAILS_SUCCESS';
export const GET_PACKAGE_DETAILS_FAILED = 'GET_PACKAGE_DETAILS_FAILED';

export const GET_USER_PACKAGE_INDEX = 'GET_USER_PACKAGE_INDEX';
export const GET_USER_PACKAGE_INDEX_SUCCESS = 'GET_USER_PACKAGE_INDEX_SUCCESS';
export const GET_USER_PACKAGE_INDEX_FAILED = 'GET_USER_PACKAGE_INDEX_FAILED';

export const BOOKING_PACKAGE = 'BOOKING_PACKAGE';
export const BOOKING_PACKAGE_SUCCESS = 'BOOKING_PACKAGE_SUCCESS';
export const BOOKING_PACKAGE_FAILED = 'BOOKING_PACKAGE_FAILED';
export const RESET_PACKAGE = 'RESET_PACKAGE';

export const CHECK_CREDIT = 'CHECK_CREDIT';
export const CHECK_CREDIT_SUCCESS = 'CHECK_CREDIT_SUCCESS';
export const CHECK_CREDIT_FAILED = 'CHECK_CREDIT_FAILED';

export const RESET_CHECK_CREDIT = 'RESET_CHECK_CREDIT';


export const USE_CREDIT = 'USE_CREDIT';
export const USE_CREDIT_SUCCESS = 'USE_CREDIT_SUCCESS';
export const USE_CREDIT_FAILED = 'USE_CREDIT_FAILED';
export const RESET_USE_CREDIT = 'RESET_USE_CREDIT';


export const GET_ALL_TICKET = 'GET_ALL_TICKET';
export const GET_ALL_TICKET_SUCCESS = 'GET_ALL_TICKET_SUCCESS';
export const GET_ALL_TICKET_FIALED = 'GET_ALL_TICKET_FAILED';

export const GET_OPEN_TICKET = 'GET_OPEN_TICKET';
export const GET_OPEN_TICKET_SUCCESS = 'GET_OPEN_TICKET_SUCCESS';
export const GET_OPEN_TICKET_FIALED = 'GET_OPEN_TICKET_FAILED';

export const GET_CLOSE_TICKET = 'GET_CLOSE_TICKET';
export const GET_CLOSE_TICKET_SUCCESS = 'GET_CLOSE_TICKET_SUCCESS';
export const GET_CLOSE_TICKET_FAILED = 'GET_CLOSE_TICKET_FAILED';

export const GET_TICKET_CATEGORIES = 'GET_TICKET_CATEGORIES';
export const GET_TICKET_CATEGORIES_SUCCESS = 'GET_TICKET_CATEGORIES_SUCCESS';
export const GET_TICKET_CATEGORIES_FAILED = 'GET_TICKET_CATEGORIES_FAILED';

export const GET_TICKET_PRIORITIES = 'GET_TICKET_PRIORITIES';
export const GET_TICKET_PRIORITIES_SUCCESS = 'GET_TICKET_PRIORITIES_SUCCESS';
export const GET_TICKET_PRIORITIES_FAILED = 'GET_TICKET_PRIORITIES_FAILED';

export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED';

export const GET_TICKET = 'GET_TICKET';
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
export const GET_TICKET_FAILED = 'GET_TICKET_FAILED';

export const GET_TICKET_MESSAGE = 'GET_TICKET_MESSAGE';
export const GET_TICKET_MESSAGE_SUCCESS = 'GET_TICKET_MESSAGE_SUCCESS';
export const GET_TICKET_MESSAGE_FAILED = 'GET_TICKET_MESSAGE_FAILED';

export const SEND_TICKET_MESSAGE = 'SEND_TICKET_MESSAGE';
export const SEND_TICKET_MESSAGE_SUCCESS = 'SEND_TICKET_MESSAGE_SUCCESS';
export const SEND_TICKET_MESSAGE_FAILED = 'SEND_TICKET_MESSAGE_FAILED';

export const CLOSE_TICKET = 'CLOSE_TICKET';
export const CLOSE_TICKET_FAILED = 'CLOSE_TICKET_FAILED';

export const OPEN_TICKET = 'OPEN_TICKET';
export const OPEN_TICKET_FAILED = 'OPEN_TICKET_FAILED';

export const PRACTICE_SCORING = 'PRACTICE_SCORING';
export const PRACTICE_SCORING_SUCCESS = 'PRACTICE_SCORING_SUCCESS';
export const PRACTICE_SCORING_FAILED = 'PRACTICE_SCORING_FAILED';
export const STOP_SCORE_REQUEST = 'STOP_SCORE_REQUEST';

export const PRACTICE_BULK_SCORE = 'PRACTICE_BULK_SCORE';
export const PRACTICE_BULK_SCORE_SUCCESS = 'PRACTICE_BULK_SCORE_SUCCESS';
export const PRACTICE_BULK_SCORE_FAILED = 'PRACTICE_BULK_SCORE_FAILED';

export const MOCK_TEST_SCORING = 'MOCK_TEST_SCORING';
export const MOCK_TEST_SCORING_SUCCESS = 'MOCK_TEST_SCORING_SUCCESS';
export const MOCK_TEST_SCORING_FAILED = 'MOCK_TEST_SCORING_FAILED';
export const SET_CURRENT_MOCK_TEST = 'SET_CURRENT_MOCK_TEST';

export const MOCK_TEST_GRADING = 'MOCK_TEST_GRADING';
export const MOCK_TEST_GRADING_SUCCESS = 'MOCK_TEST_GRADING_SUCCESS';
export const MOCK_TEST_GRADING_FAILED = 'MOCK_TEST_GRADING_FAILED';

export const GET_POST_LIST = 'GET_POST_LIST';
export const GET_POST_LIST_SUCCESS = 'GET_POST_LIST_SUCCESS';
export const GET_POST_LIST_FAILED = 'GET_POST_LIST_FAILED';

export const GET_NOTIFICATION_POST_LIST = 'GET_NOTIFICATION_POST_LIST';
export const GET_NOTIFICATION_POST_LIST_SUCCESS = 'GET_NOTIFICATION_POST_LIST_SUCCESS';
export const GET_NOTIFICATION_POST_LIST_FAILED = 'GET_NOTIFICATION_POST_LIST_FAILED';

export const GET_BANNER_POST_LIST = 'GET_BANNER_POST_LIST';
export const GET_BANNER_POST_LIST_SUCCESS = 'GET_BANNER_POST_LIST_SUCCESS';
export const GET_BANNER_POST_LIST_FAILED = 'GET_BANNER_POST_LIST_FAILED';

export const GET_VIDEOS_POST_LIST = 'GET_VIDEOS_POST_LIST';
export const GET_VIDEOS_POST_LIST_SUCCESS = 'GET_VIDEOS_POST_LIST_SUCCESS';
export const GET_VIDEOS_POST_LIST_FAILED = 'GET_VIDEOS_POST_LIST_FAILED';



export const GET_POST_CATEGORY = 'GET_POST_CATEGORY';
export const GET_POST_CATEGORY_SUCCESS = 'GET_POST_CATEGORY_SUCCESS';
export const GET_POST_CATEGORY_FAILED = 'GET_POST_CATEGORY_FAILED';

export const SET_REMANING_AI_SCORE = 'SET_REMANING_AI_SCORE';

export const GET_AI_CALL_STATUS = 'GET_AI_CALL_STATUS';
export const GET_AI_CALL_STATUS_SUCCESS = 'GET_AI_CALL_STATUS_SUCCESS';

export const GET_QUESTIONS_NAVIGATION = 'GET_QUESTIONS_NAVIGATION';
export const GET_QUESTIONS_NAVIGATION_SUCCESS = 'GET_QUESTIONS_NAVIGATION_SUCCESS';
export const GET_QUESTIONS_NAVIGATION_FAILED = 'GET_QUESTIONS_NAVIGATION_FAILED';

export const GET_COURSE_CATEGORIES = 'GET_COURSE_CATEGORIES';
export const GET_COURSE_CATEGORIES_SUCCESS = 'GET_COURSE_CATEGORIES_SUCCESS';
export const GET_COURSE_CATEGORIES_FAILED = 'GET_COURSE_CATEGORIES_FAILED';

export const GET_COURSE_SUBCATEGORIES = 'GET_COURSE_SUBCATEGORIES';
export const GET_COURSE_SUBCATEGORIES_SUCCESS = 'GET_COURSE_SUBCATEGORIES_SUCCESS';
export const GET_COURSE_SUBCATEGORIES_FAILED = 'GET_COURSE_SUBCATEGORIES_FAILED';

export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_COURSE_FAILED = 'GET_COURSE_FAILED';

export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAILED = 'GET_COURSES_FAILED';
export const CLEAR_COURSES = 'CLEAR_COURSES';

export const CHECK_COUPON = 'CHECK_COUPON';
export const CHECK_COUPON_SUCCESS = 'CHECK_COUPON_SUCCESS';
export const CHECK_COUPON_FAILED = 'CHECK_COUPON_FAILED';
export const CHECK_COUPON_CLEAR = 'CHECK_COUPON_CLEAR';

export const PRACTICENOW_CREATE_SESSION = 'PRACTICENOW_CREATE_SESSION';
export const PRACTICENOW_CREATE_SESSION_FAILED = 'PRACTICENOW_CREATE_SESSION_FAILED';
export const PRACTICENOW_CREATE_SESSION_SUCCESS = 'PRACTICENOW_CREATE_SESSION_SUCCESS';

export const PRACTICENOW_SUBMIT_ANSWER = 'PRACTICENOW_SUBMIT_ANSWER';
export const PRACTICENOW_SUBMIT_ANSWER_FAILED = 'PRACTICENOW_SUBMIT_ANSWER_FAILED';
export const PRACTICENOW_SUBMIT_ANSWER_SUCCESS = 'PRACTICENOW_SUBMIT_ANSWER_SUCCESS';

export const PRACTICENOW_GET_QUESTION = 'PRACTICENOW_GET_QUESTION';
export const PRACTICENOW_GET_QUESTION_FAILED = 'PRACTICENOW_GET_QUESTION_FAILED';
export const PRACTICENOW_GET_QUESTION_SUCCESS = 'PRACTICENOW_GET_QUESTION_SUCCESS';

export const PRACTICENOW_GET_SCORE = 'PRACTICENOW_GET_SCORE';
export const PRACTICENOW_GET_SCORE_FAILED = 'PRACTICENOW_GET_SCORE_FAILED';
export const PRACTICENOW_GET_SCORE_SUCCESS = 'PRACTICENOW_GET_SCORE_SUCCESS';

export const PRACTICENOW_GET_SESSION_STATUS = 'PRACTICENOW_GET_SESSION_STATUS';
export const PRACTICENOW_GET_SESSION_STATUS_FAILED = 'PRACTICENOW_GET_SESSION_STATUS_FAILED';
export const PRACTICENOW_GET_SESSION_STATUS_SUCCESS = 'PRACTICENOW_GET_SESSION_STATUS_SUCCESS';

export const SET_PRACTICENOW_ANSWERS = 'SET_PRACTICENOW_ANSWERS';

export const MANUAL_MOCK_FINISH = 'MANUAL_MOCK_FINISH';
export const MANUAL_MOCK_FINISH_SUCCESS = 'MANUAL_MOCK_FINISH_SUCCESS';
export const MANUAL_MOCK_FINISH_FAILED = 'MANUAL_MOCK_FINISH_FAILED';

export const SKIP_MOCK_QUESTION = 'SKIP_MOCK_QUESTION';
export const SKIP_MOCK_QUESTION_SUCCESS = 'SKIP_MOCK_QUESTION_SUCCESS';
export const SKIP_MOCK_QUESTION_FAILED = 'SKIP_MOCK_QUESTION_FAILED';




export const CREATE_SMART_ADVISOR_MODAL = 'CREATE_SMART_ADVISOR_MODAL';
export const CREATE_SMART_ADVISOR_MODAL_SUCCESS = 'CREATE_SMART_ADVISOR_MODAL_SUCCESS';
export const CREATE_SMART_ADVISOR_MODAL_FAILD = 'CREATE_SMART_ADVISOR_MODAL_FAILD';
export const SET_SHOW_UP_SMART_ADVISOR_MODAL = 'SET_SHOW_UP_SMART_ADVISOR_MODAL';


export const CREATE_STUDY_PLAN = 'CREATE_STUDY_PLAN';
export const CREATE_STUDY_PLAN_SUCCESS = 'CREATE_STUDY_PLAN_SUCCESS';
export const CREATE_STUDY_PLAN_FAILD = 'CREATE_STUDY_PLAN_FAILD';

export const GET_STUDY_PLAN = 'GET_STUDY_PLAN';
export const GET_STUDY_PLAN_SUCCESS = 'GET_STUDY_PLAN_SUCCESS';
export const GET_STUDY_PLAN_FAILD = 'GET_STUDY_PLAN_FAILD';

export const SET_SHOW_UP_MODAL = 'SET_SHOW_UP_MODAL';
export const RESET_STUDY_PLAN = 'RESET_STUDY_PLAN';

export const GET_PRACTICE_PROGRESS = 'GET_PRACTICE_PROGRESS';
export const GET_PRACTICE_PROGRESS_SUCCESS = 'GET_PRACTICE_PROGRESS_SUCCESS';
export const GET_PRACTICE_PROGRESS_FAILD = 'GET_PRACTICE_PROGRESS_FAILD';

export const GET_MOCK_TESTS = 'GET_MOCK_TESTS';
export const GET_MOCK_TESTS_SUCCESS = 'GET_MOCK_TESTS_SUCCESS';
export const GET_MOCK_TESTS_FAILD = 'GET_MOCK_TESTS_FAILD';

export const GET_MOCK_TESTS_ACCURACY = 'GET_MOCK_TESTS_ACCURACY';
export const GET_MOCK_TESTS_ACCURACY_SUCCESS = 'GET_MOCK_TESTS_ACCURACY_SUCCESS';
export const GET_MOCK_TESTS_ACCURACY_FAILD = 'GET_MOCK_TESTS_ACCURACY_FAILD';

export const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';


export const SET_FEEDBACK = 'SET_FEEDBACK';
export const SET_FEEDBACK_SUCCESS = 'SET_FEEDBACK_SUCCESS';
export const SET_FEEDBACK_FAILD = 'SET_FEEDBACK_FAILD';
export const RESET_FEEDBACK = 'RESET_FEEDBACK';

export const GET_ASSISTANTS_INDEX = 'GET_ASSISTANTS_INDEX';
export const GET_ASSISTANTS_INDEX_SUCCESS = 'GET_ASSISTANTS_INDEX_SUCCESS';
export const GET_ASSISTANTS_INDEX_FAILED = 'GET_ASSISTANTS_INDEX_FAILED';

export const USE_ASSISTANT = 'USE_ASSISTANT';
export const USE_ASSISTANT_SUCCESS = 'USE_ASSISTANT_SUCCESS';
export const USE_ASSISTANT_FAILED = 'USE_ASSISTANT_FAILED';

export const GET_STUDY_PATHWAY = 'GET_STUDY_PATHWAY';
export const GET_STUDY_PATHWAY_SUCCESS = 'GET_STUDY_PATHWAY_SUCCESS';
export const GET_STUDY_PATHWAY_FAILED = 'GET_STUDY_PATHWAY_FAILED';

export const GET_USABLE_USER_PACKAGE = 'GET_USABLE_USER_PACKAGE';
export const GET_USABLE_USER_PACKAGE_SUCCESS = 'GET_USABLE_USER_PACKAGE_SUCCESS';
export const GET_USABLE_USER_PACKAGE_FAILED = 'GET_USABLE_USER_PACKAGE_FAILED';

export const GET_DASHBOARD_FILTER_DATA = 'GET_DASHBOARD_FILTER_DATA';
export const GET_DASHBOARD_FILTER_DATA_SUCCESS = 'GET_DASHBOARD_FILTER_DATA_SUCCESS';
export const GET_DASHBOARD_FILTER_DATA_FAILED = 'GET_DASHBOARD_FILTER_DATA_FAILED';

export const GET_DASHBOARD_SEARCH_QUESTION_DATA = 'GET_DASHBOARD_SEARCH_QUESTION_DATA';
export const GET_DASHBOARD_SEARCH_QUESTION_DATA_SUCCESS = 'GET_DASHBOARD_SEARCH_QUESTION_DATA_SUCCESS';
export const GET_DASHBOARD_SEARCH_QUESTION_DATA_FAILED = 'GET_DASHBOARD_SEARCH_QUESTION_DATA_FAILED';


export const GET_DASHBOARD_FREE_VIDEO = 'GET_DASHBOARD_FREE_VIDEO';
export const GET_DASHBOARD_FREE_VIDEO_SUCCESS = 'GET_DASHBOARD_FREE_VIDEO_SUCCESS';
export const GET_DASHBOARD_FREE_VIDEO_FAILED = 'GET_DASHBOARD_FREE_VIDEO_FAILED';

export const SHOW_PROGRESS = 'SHOW_PROGRESS';

export const MOCK_TEST_SECTION = 'MOCK_TEST_SECTION';
export const MOCK_TEST_SECTION_SUCCESS = 'MOCK_TEST_SECTION_SUCCESS';
export const MOCK_TEST_SECTION_FAILED = 'MOCK_TEST_SECTION_FAILED';

export const MOCK_TEST_QUESTION = 'MOCK_TEST_QUESTION';
export const MOCK_TEST_QUESTION_SUCCESS = 'MOCK_TEST_QUESTION_SUCCESS';
export const MOCK_TEST_QUESTION_FAILED = 'MOCK_TEST_QUESTION_FAILED';

export const DELETE_MOCK_USER = 'DELETE_MOCK_USER';
export const DELETE_MOCK_USER_SUCCESS = 'DELETE_MOCK_USER_SUCCESS';
export const DELETE_MOCK_USER_FAILED = 'DELETE_MOCK_USER_FAILED';

export const RECORD_iNTRO = 'RECORD_iNTRO';

export const RECORD_STOP_SAVE = 'RECORD_STOP_SAVE';

export const  SAVE_AND_EXIT_ALERT_MODEL = 'SAVE_AND_EXIT_ALERT_MODEL';
export const CONTINUE_AND_RESTART_MODEL = 'CONTINUE_AND_RESTART_MODEL';
export const CONFIRM_MODEL = 'CONFIRM_MODEL';
export const CANNOT_SKIP_MODEL = 'CANNOT_SKIP_MODEL';
export const SUBMISSION_SUCCESS_MODAL = 'SUBMISSION_SUCCESS_MODAL';
export const FINISH_EXAM_MODEL = 'FINISH_EXAM_MODEL';
export const RECORDING_STOPPED_MODAL = 'RECORDING_STOPPED_MODAL';
export const UPGRADE_PACKAGE_MODAL = 'UPGRADE_PACKAGE_MODAL';
export const TEST_RESUME_MODAL = 'TEST_RESUME_MODAL';
export const MAIN_TIMES_UP_MODAL = 'MAIN_TIMES_UP_MODAL';
export const RESTART_EXAM_WARNING_MODAL = 'RESTART_EXAM_WARNING_MODAL'
export const TEST_TYPE = 'TEST_TYPE';
export const IS_LOADER = 'IS_LOADER';

export const BRACK_MODEL = 'BRACK_MODEL';
export const SUBMISSION_CHECK_RESULT_MODAL = 'SUBMISSION_CHECK_RESULT_MODAL';

export const GET_QUESTIONS_ANSWER_MOCK = 'GET_QUESTIONS_ANSWER_MOCK';
export const GET_QUESTIONS_ANSWER_MOCK_SUCCESS = 'GET_QUESTIONS_ANSWER_MOCK_SUCCESS';
export const GET_QUESTIONS_ANSWER_MOCK_FAILED = 'GET_QUESTIONS_ANSWER_MOCK_FAILED';

export const GET_RESULT_MOCK = 'GET_RESULT_MOCK';
export const GET_RESULT_MOCK_SUCCESS = 'GET_RESULT_MOCK_SUCCESS';
export const GET_RESULT_MOCK_FAILED = 'GET_RESULT_MOCK_FAILED';

export const START_PLAYER_MOCK_RESULT = 'START_PLAYER_MOCK_RESULT';
export const STOP_PLAYER_MOCK_RESULT = 'STOP_PLAYER_MOCK_RESULT';
export const SET_DEVICE_ENVIRONMENT = 'SET_DEVICE_ENVIRONMENT'